<template>
  <div class="wrapper-content">
    <div class="nav">
      <el-breadcrumb separator-class="el-icon-arrow-right" class="breadcrumb">
        <el-breadcrumb-item :to="{ path: '/course' }">教学服务</el-breadcrumb-item>
        <el-breadcrumb-item>{{ course.name }}</el-breadcrumb-item>
      </el-breadcrumb>
    </div>

    <div class="detail">
      <div class="detail-image">
        <el-image v-if="course.coverImg" :src="loadUrl(course.coverImg)" fit="cover">
          <div slot="placeholder" class="image-slot">加载中<span class="dot">...</span></div>
        </el-image>
      </div>
      <div class="detail-right">
        <h2>
          {{ course.name }}
          <el-tag v-if="course.status == 5" type="success" effect="dark" size="mini">筹备中</el-tag>
          <el-tag v-if="course.status == 7" type="danger" effect="dark" size="mini">报名中</el-tag>
          <el-tag v-if="course.status == 8" type="info" effect="dark" size="mini">报名已结束</el-tag>
          <el-tag v-if="course.status == 9" effect="dark" size="mini">活动中</el-tag>
          <el-tag v-if="course.status == 10" type="info" effect="dark" size="mini">活动已结束</el-tag>
        </h2>
        <div class="detail-right-textbg">
          <p>
            <span class="name">授课时间：</span>
            <span class="value">{{ formatDateYMD(course.startTime) }} -{{ formatDateYMD(course.endTime) }}</span>
          </p>
          <p>
            <span class="name">授课地址：</span>
            <span class="value">{{ course.address }}</span>
          </p>
          <p>
            <span class="name">付费类型：</span>
            <span class="value red fz22" v-if="course.collectFee == 1">
              <i class="f12">¥</i>{{ course.courseFee }}
<!--              <span class="discount" v-if="course.memberFee">会员价：¥{{ course.memberFee }}</span>-->
            </span>
            <span class="price" v-else>免费</span>
          </p>
        </div>
        <div class="detail-right-text">
          <p>
            <span class="name">报名时间：</span>
            <span class="value">{{ formatDateYMD(course.signStartTime) }} -{{ formatDateYMD(course.signEndTime) }}</span>
          </p>
          <p>
            <span class="name">授课老师：</span>
            <span class="value">{{ course.teacherName }}</span>
          </p>
          <p>
            <span class="name">授课方式：</span>
            <span class="value">{{ course.model == 1 ? '线上视频' : course.model == 2 ? '线上资料' : '线下' }}</span>
          </p>
          <p>
            <span class="name">授课时长：</span>
            <span class="value">{{course.hour||0}}小时{{course.minutes||0}}分钟</span>
          </p>
        </div>
        <template v-if="course.toMember==1">
          <el-button type="primary" @click="signup" :disabled="course.status != 7 || !isMember">立即报名</el-button>
          <span class="vipIcon" v-if="course.toMember==1">(注：<img src="../../assets/image/vip-crownl.png" height="200" width="200"/>仅限会员报名)</span>
        </template>
        <template v-else>
          <el-button type="primary" @click="signup" :disabled="course.status != 7">立即报名</el-button>
        </template>
<!--        <el-button v-if="course.registered" type="primary" plain @click="linkOrderInfo">我的报名</el-button>-->
      </div>
    </div>

    <el-tabs v-model="activeName" class="detail-tab">
      <el-tab-pane label="课程描述" name="1">{{ course.courseInt }}</el-tab-pane>
      <el-tab-pane label="课程目录" name="2">
        <ul class="course-menu">
          <li class="menu" v-for="(item,index) in course.courseDirectoryListVos" :key="index">
            <div class="menu-item"><span style="padding-right: 6px">{{ index + 1 }}.</span><span>{{ item.title }}</span></div>
            <ul class="course-class">
              <li v-for="(items) in item.courseDirectoryListVos" :key="items.id">
                <div class="class-item">
                  <i v-if="items.materialType==1" class="el-icon-video-camera"></i>
                  <i v-else-if="items.materialType==2" class="el-icon-document"></i>
                  <div class="class-title">{{ items.title }}</div>
                </div>
              </li>
            </ul>
          </li>
        </ul>
      </el-tab-pane>
<!--      <el-tab-pane label="报名条件" name="3">-->
<!--        <ul>-->
<!--          <li v-for="(item,index) in course.rules" :key="index" class="rules">{{ item }}</li>-->
<!--        </ul>-->
<!--      </el-tab-pane>-->
    </el-tabs>
  </div>
</template>

<script>
import {
  getCourseDetail
} from '@/api/course'
import {courseCourseInfoInfo} from "../../api/course";
import {memberIsMember} from "../../api/partners";

export default {
  name: 'course-detail',
  components: {},
  props: {
    id: String
  },
  data() {
    return {
      activeName: '1',
      course: {},
      bigDialogVisible: false,
      isMember:false
    }
  },
  created() {
    this.initData()
  },
  methods: {
    initData(){
      this.$store.commit('showLoading')
      courseCourseInfoInfo(this.id).then(res=>{
        if(res.code == 200){
          this.course = res.data
          this.$store.commit('hideLoading')
          this.getMemberIsMember(res.data.teacherId)
        }else {
          this.$message(res.msg)
          this.$store.commit('hideLoading')
        }
      }).catch(()=>{
        this.$message('请求出错，请重新刷新页面！');
        this.$store.commit('hideLoading')
      })
    },
    //查询当前用户是否为会员
    getMemberIsMember(tenantId) {
      let userId = window.localStorage.getItem("user")&&JSON.parse(window.localStorage.getItem("user")).userInfo.userId || "";
      memberIsMember(tenantId, userId).then((res) => {
        if (res.code == 200) {
          this.isMember = res.data;
        }
      });
    },
    init() {
      const self = this
      self.$store.commit('showLoading')
      getCourseDetail({ id: this.id }).then((res) => {
        if (res.code == 200) {
          self.course = res.data
          let teacherList = []
          self.course.teacherInfos.map((item) => {
            teacherList.push(item.name)
          })
          this.teachers = teacherList.join(',')
          this.course.discounts.map((item) => {
            if (item.discountType == 4) {
              this.discountPrice = item.discount
            } else if (item.discountType == 3) {
              this.discountPrice = this.course.courseFee * item.discount * 0.01
            }
          })
          self.$store.commit('hideLoading')
        } else {
          self.$message(res.msg)
        }
      })
    },
    signup() {
      // if (this.course.registered) {
      //   this.$message({type: 'warning', message: '请勿重复报名'})
      //   return
      // } else {
      //   this.$router.push({path: '/course-signup', query: {courseId: this.id}})
      // }
      this.$router.push({path: '/course-signup', query: {courseId: this.id}})
    },
    linkOrderInfo() {
      this.$router.push({path: '/course-signup-detail', query: {courseId: this.id}})
    }
  }
}
</script>

<style lang="scss" scoped>
.detail {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  padding-top: 26px;

  .detail-image {
    flex-shrink: 0;
    width: 460px;
    height: 338px;
    margin-right: 20px;

    .el-image {
      width: 100%;
      height: 100%;
    }
  }

  .detail-right {
    flex: 1;
    font-size: 14px;
    line-height: 20px;
    font-weight: 400;
    color: #555555;

    h2 {
      height: 22px;
      line-height: 22px;
      font-size: 22px;
      font-weight: bold;
      color: #333333;
    }
  }

  .detail-right-text,
  .detail-right-textbg {
    padding: 4px 8px;
    line-height: 26px;
    margin-bottom: 10px;

    p {
      display: flex;
      align-items: start;
      margin-bottom: 5px;
    }

    .name {
      flex-shrink: 0;
      width: 70px;
      text-align: right;
      color: #999;
    }

    .value {
      flex: 1;
    }

    .red {
      color: #ff1d00;
    }

    .fz22 {
      font-size: 22px;
    }

    i.f12 {
      font-size: 12px;
    }

    .price {
      display: flex;
      align-items: center;
      font-size: 22px;
      font-weight: 400;
      color: #ff1d00;
      margin-right: 6px;
      width: 158px;
      justify-content: space-between;
    }

    .discount {
      //display: flex;
      //align-items: center;
      //justify-content: center;
      padding: 0 10px;
      min-width: 110px;
      height: 22px;
      background: #FF8001;
      border-radius: 11px;
      color: #fff;
      font-size: 14px;
      margin-left: 10px;
    }
  }

  .detail-right-textbg {
    margin-top: 20px;
    margin-bottom: 0;
    line-height: 31px;
    background: url("../../assets/image/detail-textbg.png") center no-repeat;
  }
}

.detail-tab {
  width: 100%;
  margin: 40px 0;
  min-height: 300px;
  color: #555;

  /deep/ .el-tabs__nav-wrap::after {
    height: 1px;
    background-color: #e8e8e8;
  }

  //::v-deep .el-tabs__item{
  //  width: 90px;
  //  color: #333333;
  //}


  .course-menu {
    .menu {
      font-size: 16px;
      font-weight: bold;
      color: #333333;
      margin-bottom: 24px;

      .menu-item {
        margin-bottom: 14px;
        padding-left: 26px;
      }
    }
  }

  .course-class {
    li {
      width: 1200px;
      height: 56px;
      background: #EEEEEE;
      border-radius: 6px;
      font-size: 14px;
      font-weight: 400;
      color: #555555;
      margin-bottom: 14px;
      display: flex;
      align-items: center;

      .class-item {
        padding-left: 26px;
        display: flex;
        align-items: center;
        height: 100%;

        i {
          font-size: 20px;
          padding-right: 15px;
        }

        .class-title {
          height: 100%;
          line-height: 53px;
          font-size: 14px;
        }
      }
    }
  }
}

.detail-item-list {
  padding-bottom: 22px;
  display: flex;
  flex-wrap: wrap;

  li {
    width: 132px;
    height: 34px;
    line-height: 34px;
    background: #ffffff;
    border: 1px solid #dddddd;
    border-radius: 4px;
    margin-left: 9px;
    font-size: 14px;

    .el-button--text {
      color: #333333;
      padding: 0 12px;
      white-space: nowrap;
      overflow: hidden;
      height: 100%;
      text-overflow: ellipsis;
      width: 141px;
    }

    &:first-child {
      flex-shrink: 0;
      width: 70px;
      height: 15px;
      line-height: 15px;
      color: #999999;
      border: 0;
    }

    &:hover,
    &.onSel {
      border-color: #1e50ae;
      color: #1e50ae;
      cursor: pointer;
    }

    &.onSel {
      position: relative;
      background-color: #fff;

      &:before {
        position: absolute;
        font-family: iconfont;
        content: "\ec9e";
        bottom: 0;
        right: 0;
        font-size: 10px;
        line-height: 10px;
        color: #fff;
        z-index: 4;
      }

      &:after {
        position: absolute;
        content: "";
        bottom: 0;
        right: -0.5px;
        width: 0;
        height: 0;
        border-bottom: 18px solid #1e50ae;
        border-left: 20px solid transparent;
      }
    }
  }
}

// 弹出层
.certificationDialog {
  .bold {
    font-weight: bold;
  }

  /deep/ .el-form-item__label {
    text-align: left;
  }

  /deep/ .el-dialog {
    width: 586px;
    box-shadow: 0px 9px 40px 0px rgba(75, 75, 75, 0.12);
    border-radius: 10px;
  }

  /deep/ .el-dialog__header {
    text-align: left;
    font-size: 18px;
    color: #333333;
    padding-bottom: 18px;
    border-bottom: solid 1px #e5e5e5;
  }
}

.confirmation-info {
  line-height: 42px;
  font-size: 14px;
  font-weight: 400;
  color: #555555;
}

.rules {
  padding-bottom: 8px;
}

.nav {
  margin-top: 16px;

  .breadcrumb {
    font-size: 13px;
    color: #666666;
  }
}
.vipIcon{
  vertical-align: bottom;
  margin-left: 10px;
  color: #00A384;
  font-weight: bold;
  img{
    width: 20px;
    height: 20px;
  }
}

</style>
